/** @format */

import "preline/preline";

import Alpine from "alpinejs";

window.Alpine = Alpine;

Alpine.start();

// Load the external SVG sprite sheet
fetch("/assets/logos.svg")
	.then((response) => response.text())
	.then((data) => {
		const spriteContainer = document.getElementById("svg-sprite");
		spriteContainer.innerHTML = data;

		// Function to set the viewBox attribute dynamically
		document.querySelectorAll("svg[data-symbol-id]").forEach((svg) => {
			const symbolId = svg.getAttribute("data-symbol-id");
			const symbol = document.getElementById(symbolId);
			if (symbol) {
				const viewBox = symbol.getAttribute("viewBox");
				if (viewBox) {
					svg.setAttribute("viewBox", viewBox);
				}
			}
		});
	})
	.catch((error) => console.error("Error loading the SVG sprite:", error));

// end sprite handler

// Menu dropdown
// document.addEventListener("DOMContentLoaded", function () {
// 	const button = document.getElementById("navbar-toggle");
// 	const menu = document.getElementById("navbar-dropdown");

// 	const dropdownButton = document.getElementById("dropdownNavbarLink");
// 	const dropdownMenu = document.getElementById("dropdownNavbar");

// 	dropdownButton.addEventListener("click", () => {
// 		dropdownMenu.classList.toggle("hidden");
// 	});

// 	button.addEventListener("click", function () {
// 		menu.classList.toggle("hidden");
// 	});
// });
